import React from 'react';

import useClassy from '@core/hooks/useClassy';

import SafeStyles from '@ui/SafeStyles';

import classes from './index.module.scss';

interface BasePanelProps {
  children: React.ReactNode;
  className?: string;
  /**
   * Screen reader label for the panel.
   */
  label: string;
  /**
   * Allow or deny access to the child node(s) from JavaScript outside the <SafeStyles> shadow boundary
   */
  noJS?: boolean;
  /**
   * Additional class name to apply to the root element of the panel.
   */
  rootClassName?: string;
  /**
   * Render the child node(s) in a shadow DOM using the <SafeStyles> component
   */
  safeStyles?: boolean;
}

/**
 * Base container for admin UI that appears in the SuperHub routes.
 *
 * Children will be rendered within a SafeStyles component to ensure
 * that any custom CSS cannot affect the panel content. Some base styles
 * are applied to the panel as well.
 *
 * @example
 * ```ts
 * <BasePanel label="Projects">
 *   <ProjectList />
 * </BasePanel>
 * ```
 */
export default function BasePanel({
  children,
  className,
  label,
  noJS = true,
  rootClassName,
  safeStyles = true,
}: BasePanelProps) {
  const bem = useClassy(classes, 'BasePanel');

  return safeStyles ? (
    <SafeStyles
      aria-label={label}
      className={bem('&', className)}
      exclude={['rm-custom-css']}
      noJS={noJS}
      role="region"
    >
      <div className={bem('-root', rootClassName)} data-color-mode="dark">
        {children}
      </div>
    </SafeStyles>
  ) : (
    <div aria-label={label} className={bem('&', className)} role="region">
      <div className={bem('-root', rootClassName)} data-color-mode="dark">
        {children}
      </div>
    </div>
  );
}
