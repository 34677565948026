import React, { useMemo, useState } from 'react';

import useEnvInfo from '@core/hooks/useEnvInfo';

export type DocumentStylesheetExclude = string[];

/**
 * Check if the browser supports CSSStyleSheet constructor
 * https://developer.mozilla.org/en-US/docs/Web/API/CSSStyleSheet/CSSStyleSheet
 * Lacks support in IE11 and JS DOM (our test environment), but is supported in all modern browsers
 */
function suppportsConstructableStylesheets() {
  return 'replaceSync' in CSSStyleSheet.prototype;
}

/**
 * Get inline stylesheets from the document and convert them to constructed
 * CSSStyleSheets. Returns an array of constructed `CSSStyleSheet`s to be used
 * by `ShadowRoot.adoptedStylesheets` in the consumer component
 */
function getInlineStylesheets(exclude: DocumentStylesheetExclude, isClient: boolean) {
  if (!isClient || !suppportsConstructableStylesheets()) return [];

  return Array.from(document.styleSheets)
    .filter(stylesheet => {
      return !exclude.includes(stylesheet.title || '') && !stylesheet.href;
    })
    .map(stylesheet => {
      const cssRules = Array.from(stylesheet.cssRules)
        .map(rule => rule.cssText)
        .join('\n');

      const sheet = new CSSStyleSheet();
      sheet.replaceSync(cssRules);
      return sheet;
    });
}

/**
 * Get linked stylesheets from the document and convert them to <link> React
 * elements. Returns an array of HTMLLinkElement that can be spread inside the
 * consumer component
 */
function getLinkedStylesheets(exclude: DocumentStylesheetExclude, isClient: boolean) {
  if (!isClient) {
    return {
      linkedStylesheets: undefined,
      linkedStylesheetsLoaded: Promise.resolve(),
    };
  }

  const pendingSheets: Promise<void>[] = [];
  const pendingResolvers: ((value: void) => void)[] = [];

  const linkedStylesheets = Array.from(document.styleSheets)
    .filter(stylesheet => {
      return !exclude.includes(stylesheet.title || '') && stylesheet.href;
    })
    .map((stylesheet, idx) => {
      pendingSheets.push(
        new Promise(resolve => {
          pendingResolvers[idx] = resolve;
        }),
      );

      return (
        <link
          key={`${stylesheet.href}-${idx}`}
          href={stylesheet.href || ''}
          onError={() => pendingResolvers[idx]()}
          onLoad={() => pendingResolvers[idx]()}
          rel="stylesheet"
          type="text/css"
        />
      );
    });

  return {
    linkedStylesheets,
    linkedStylesheetsLoaded: Promise.all(pendingSheets),
  };
}

/**
 * Get inline and linked stylesheets from `document.styleSheets`.
 * Used by `@ui/SafeStyles` for adding global stylesheets into shadow DOM root.
 */
function useDocumentStylesheets({ exclude }: { exclude: DocumentStylesheetExclude }) {
  const { isClient } = useEnvInfo();
  const [isLoaded, setIsLoaded] = useState(false);

  const inlineStylesheets = useMemo(() => getInlineStylesheets(exclude, isClient), [exclude, isClient]);
  const { linkedStylesheets, linkedStylesheetsLoaded } = useMemo(
    () => getLinkedStylesheets(exclude, isClient),
    [exclude, isClient],
  );

  linkedStylesheetsLoaded.then(() => {
    setIsLoaded(true);
  });

  return {
    inlineStylesheets,
    linkedStylesheets,
    isLoaded,
  };
}

export default useDocumentStylesheets;
