import type { PayGateBannerProps } from './Banner';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';

import PayGateBanner from './Banner';
import classes from './index.module.scss';

export interface PayGateProps {
  /**
   * Whether the children content is locked or open.
   * - `locked` - User does not have access to the feature and will be prompted to upgrade.
   * - `open` - User has access to the feature and will see the children content.
   */
  access: 'locked' | 'open';
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  /**
   * The feature that is gated. Used to determine the content to display in the
   * overlay. Omit this prop to lock the feature without displaying a banner.
   */
  feature?: PayGateBannerProps['feature'];
}

/**
 * Used to gates a feature's UI if the user doesn not have the correct plan
 * permissions. When a feature is gated it displays an overlay with a message
 * and a button to upgrade the plan, and renders child content as inert in the
 * background.
 */
export default function PayGate({ access, children, className, contentClassName, feature }: PayGateProps) {
  const bem = useClassy(classes, 'PayGate');

  return access === 'open' ? (
    <>{children}</>
  ) : (
    <div className={bem('&', className)}>
      <div className={bem('-content', contentClassName)} inert="">
        {children}
      </div>
      <Flex align="center" className={bem('-overlay', !feature && '-overlay_disabled')} justify="center">
        {!!feature && <PayGateBanner feature={feature} />}
      </Flex>
    </div>
  );
}
