import type { Dispatch, SetStateAction } from 'react';

import { MetricsSDKSnippet, availableWebhookTargets } from '@readme/metrics-sdk-snippets';
import React, { useContext, useMemo, useState } from 'react';

import type { ConfigContextValue } from '@core/context';
import { ConfigContext, ProjectContext } from '@core/context';
import { useProjectStore, useReferenceStore } from '@core/store';

export const WebhooksSetupContext = React.createContext({});

type SnippetConvertFunction = typeof MetricsSDKSnippet.prototype.convert;
type ClientRanges = Extract<ReturnType<SnippetConvertFunction>, object>['ranges'];
type TargetId = Parameters<SnippetConvertFunction>[1];
type AvailableTarget = ReturnType<typeof availableWebhookTargets>[number];
type AvailableTargetClient = AvailableTarget['clients'][number];

interface Client extends Pick<AvailableTargetClient, 'key' | 'title'> {
  lambdaRuntime?: NonNullable<AvailableTargetClient['metadata']>['lambdaRuntime'];
}

interface ClientSnippetOptions {
  createKeys: boolean;
}
export interface WebhooksSetupContextValue {
  client?: Client;
  clientKey?: Client['key'];
  clients: Client[];
  clientSnippetOptions?: ClientSnippetOptions;
  ranges: ClientRanges | undefined;
  setClientKey: Dispatch<SetStateAction<Client['key'] | undefined>>;
  setClientSnippetOptions: Dispatch<SetStateAction<ClientSnippetOptions | undefined>>;
  snippet?: string;
}

function WebhooksSetupState({ children }: { children: React.ReactNode }) {
  const { project: projectFromContext } = useContext(ProjectContext);
  const [secretFromStore, variableDefaultsFromStore] = useProjectStore(store => [
    store.data.custom_login.jwt_secret,
    store.data.variable_defaults,
  ]);

  const { name } = useContext(ConfigContext) as ConfigContextValue;
  const isHub = name === 'Hub';

  const {
    jwt_secret: secret,
    variableDefaults,
    childrenProjects,
  } = useMemo(() => {
    if (isHub) {
      // TODO support enterprise in superhub via childrenProjects
      return { jwt_secret: secretFromStore, variableDefaults: variableDefaultsFromStore, childrenProjects: [] };
    }

    return projectFromContext;
  }, [isHub, projectFromContext, secretFromStore, variableDefaultsFromStore]);

  const language = useReferenceStore(s => s.language.language as TargetId | 'aws');

  const [clientKey, setClientKey] = useState<string>();
  const [clientSnippetOptions, setClientSnippetOptions] = useState<ClientSnippetOptions>();

  const variables = useMemo(() => {
    return (childrenProjects?.length ? childrenProjects : [])
      .reduce((acc, cp) => acc.concat(cp?.variableDefaults), [])
      .concat(variableDefaults ?? [])
      .filter(v => v?.source);
  }, [variableDefaults, childrenProjects]);

  const { convert } = useMemo(() => new MetricsSDKSnippet(variables, { secret }), [secret, variables]);

  const webhookTargets = useMemo(() => {
    return availableWebhookTargets();
  }, []);

  const clients = useMemo(() => {
    /**
     * Note: We're treating AWS API Gateway as an available "language",
     * so we need to gather the available clients differently
     * */
    if (language === 'aws') {
      const awsClients = webhookTargets.reduce<Client[]>((acc, target) => {
        const awsClient = target.clients.find(c => c.key === language);
        if (awsClient) {
          acc.push({
            key: target.key,
            title: target.title,
            lambdaRuntime: awsClient.metadata?.lambdaRuntime,
          });
        }
        return acc;
      }, []);

      // Default AWS client key (Lambda runtime) and createKeys options
      setClientKey(awsClients[0]?.key);
      setClientSnippetOptions({ createKeys: false });
      return awsClients;
    }

    /**
     * Note: Since AWS API Gateway will be shown as an available "language",
     * we want to filter out AWS API Gateway from appearing in Library dropdown
     * */
    const currentClients = webhookTargets
      .find(target => {
        return target.key === language;
      })
      ?.clients.filter(c => c.key !== 'aws')
      .map(({ key, title }) => ({
        key,
        title,
      }));

    setClientKey(currentClients?.[0]?.key);
    return currentClients || [];
  }, [language, webhookTargets]);

  /**
   * Regenerate snippet if language or clientKey changes
   * Note: the clientKey state value updates async, so we must check that the key exists in current clients before using
   * */
  const { snippet, ranges } = useMemo(() => {
    let key = clients.some(c => c.key === clientKey) ? clientKey : clients[0]?.key;
    let lang: TargetId;

    if (language === 'aws') {
      lang = webhookTargets.find(target => {
        return target.key === key;
      })?.key as TargetId;
      key = 'aws';
    } else {
      lang = language;
    }

    return convert('webhooks', lang, key, clientSnippetOptions) || { snippet: undefined, ranges: undefined };
  }, [clients, convert, language, clientKey, clientSnippetOptions, webhookTargets]);

  const value: WebhooksSetupContextValue = useMemo(
    () => ({
      clients,
      client: clients.find(client => client.key === clientKey),
      clientSnippetOptions,
      clientKey,
      setClientKey,
      setClientSnippetOptions,
      ranges,
      snippet,
    }),
    [clientKey, clients, clientSnippetOptions, ranges, snippet],
  );

  return <WebhooksSetupContext.Provider value={value}>{children}</WebhooksSetupContext.Provider>;
}

export default WebhooksSetupState;
