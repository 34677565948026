import type { SegmentContextValue } from '.';
import type { SegmentItemProps } from '../SegmentItem';

import React, { isValidElement, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import SegmentItem from '../SegmentItem';

import SegmentContext from '.';

interface SegmentContextProviderProps {
  children: React.ReactNode;
  selectedIndex?: number;
}

function Provider({ children, selectedIndex: propSelectedIndex = 0 }: SegmentContextProviderProps) {
  const [selectedIndex, setSelectedIndex] = useState<SegmentContextValue['selectedIndex']>(propSelectedIndex);
  const [highlightPosition, setHighlightPosition] = useState<SegmentContextValue['highlightPosition']>();

  useEffect(() => {
    if (propSelectedIndex !== undefined) {
      setSelectedIndex(propSelectedIndex);

      // When selected index is set to a negative nubmer, we interpret this as a
      // desire to clear the selection. So the highlight position must be unset.
      if (propSelectedIndex < 0) {
        setHighlightPosition(undefined);
      }
    }
  }, [propSelectedIndex]);

  return (
    <SegmentContext.Provider value={{ selectedIndex, setSelectedIndex, highlightPosition, setHighlightPosition }}>
      {children}
    </SegmentContext.Provider>
  );
}

// This HOC sets the selectedIndex based on the current pathname if no selectedIndex is provided.
// Separating this logic into a separate function allows us to only require the useLocation hook when needed
function withPathname(Component: React.ComponentType<SegmentContextProviderProps>, navLinkToPaths: string[]) {
  const WithPathname = (props: SegmentContextProviderProps) => {
    const { pathname } = useLocation();
    const activeNavItemIndex = navLinkToPaths.findIndex(path => pathname.startsWith(path));

    return (
      <Component
        {...props}
        selectedIndex={props.selectedIndex !== undefined ? props.selectedIndex : activeNavItemIndex}
      />
    );
  };

  WithPathname.displayName = `withPathname(${Component.displayName || Component.name})`;
  return WithPathname;
}

export default function SegmentContextProvider(props: SegmentContextProviderProps) {
  const navLinkPaths = useMemo(() => {
    return React.isValidElement(props.children) && props.children
      ? // We iterate ove the children of the children to find the NavLink items
        // because the child of the SegmentContextProvider is SegmentContent
        React.Children.toArray(props.children.props.children).reduce((acc: string[], child) => {
          if (isValidElement<SegmentItemProps>(child) && child.type === SegmentItem && child.props.to) {
            acc.push(child.props.to);
          }
          return acc;
        }, [])
      : [];
  }, [props.children]);

  // If the children are NavLink items, we wrap the provider in a component that sets the initialSelectedIndex
  // based on the current pathname
  return navLinkPaths.length ? withPathname(Provider, navLinkPaths)(props) : <Provider {...props} />;
}
