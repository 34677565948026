import type { InputHTMLAttributes } from 'react';

import React, { forwardRef } from 'react';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';

import classes from './style.module.scss';

export interface RadioProps {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  isCard?: boolean;
  label?: string;
  name: string;
}

const Radio = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement> & RadioProps>(
  ({ children, className, id, isCard = false, label, name, ...attrs }: RadioProps, ref) => {
    const idGenerator = useUniqueId(name);
    const uid = id || idGenerator(); // Use the id if provided, otherwise generate a unique one.
    const bem = useClassy(classes, 'Radio');

    return (
      <label className={bem('&', isCard && '_card-layout', className)} data-testid="radio" htmlFor={uid}>
        <input
          ref={ref}
          className={bem('-input', isCard && '-input_hidden')}
          id={uid}
          name={name}
          type="radio"
          {...attrs}
        />
        {isCard ? <div className={bem('-card')}>{children}</div> : children}
        {!!label && <span className={bem('-label', isCard && '-label_sm')}>{label}</span>}
      </label>
    );
  },
);

Radio.displayName = 'Radio';

export default Radio;
